<template>
    <div class="component">
        <nav class="row align-center space-between top-nav">
            <div class="nav-left row align-center">
                <div class="nav-tilte row align-center">
                    <div @click="$router.go(0)">
                        <img :src="require('../assets/images/logo/' + module + '.png')" alt="logo" height="36px" />
                    </div>
                </div>
            </div>

            <div style="margin-left: 20px;">
              <slot name="descript"></slot>
            </div>

            <div class="nav-center row align-center space-center">
                <div style="font-size:20px;" v-if="windowId === 'deptCollectMoney'">
                    {{$cache.local.get('deptName')}}/{{$cache.local.get('deptCode')}}
                    <span class="cashier-item" v-if="cashierItem">收银台：{{cashierItem.title}}</span>
                </div>
                <div style="font-size:20px;" v-if="curPosition">{{curPosition}}</div>
            </div>

            <div class="nav-right row align-center space-end">
                <p @click="lockScreen" class="row align-center" style="
                      margin-right: 10px;
                      background: #eff7ff;
                      height: 36px;
                      line-height: 36px;
                      padding: 0 10px;
                      border-radius: 8px;
                      cursor: pointer;">
                  <i class="el-icon-lock" style="font-weight: bold; color: #333;margin-right: 5px;" title="锁定"></i>
                  <span>锁定(F2)</span>
                </p>
                <p @click="$router.go(0)" class="row align-center" style="
                    margin-right: 10px;
                    background: #eff7ff;
                    height: 36px;
                    line-height: 36px;
                    padding: 0 10px;
                    border-radius: 8px;
                    cursor: pointer;">
                    <i class="el-icon-refresh-left" style="font-weight: bold; color: #333;margin-right: 5px;" title="刷新页面"></i>
                    <span>刷新页面</span>
                </p>
                <div class="min-size cursor nav-right-btn" @click="minWindow">
                    <p class="row align-center">
                        <i class="el-icon-minus size-icon" title="最小化"></i>
                        <span>最小化</span>
                    </p>
                </div>
                <div class="close-size cursor nav-right-btn" @click="closeWindow">
                    <p class="row align-center">
                        <i class="el-icon-close size-icon" title="关闭"></i>
                        <span>关闭</span>
                    </p>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
import { ipcApiRoute, specialIpcRoute } from '@/api/main'
import { ipc } from '@/utils/ipcRenderer'
import Mousetrap from 'mousetrap'
import 'mousetrap/plugins/global-bind/mousetrap-global-bind'
export default {
    name: 'stockTopMenu',
    props: {
        //组件所属功能模块  默认为stock店铺盘点模块
        module: {
            type: String,
            default: 'stock'
        },
        //当前页面标题
        curPosition: {
            type: String,
            default: ''
        },
        //窗口id
        windowId: {
            type: String,
            default: ''
        }
    },
    data(){
        return{
            winFlag: false,
            cashierItem: null
        }
    },
    created() {
        this.cashierItem = JSON.parse(this.$cache.local.get('cashierDeskItem')) || null
      Mousetrap.bind(['f2', 'f2'], () => {
        this.lockScreen()
      })
    },
    methods: {
        lockScreen(){
			ipc.invoke(ipcApiRoute.getWCid, 'main').then(id => {
				let mainWCid = id;
				if(mainWCid){
					ipc.invoke(ipcApiRoute.closeWindowAll).then(r => {})
					ipc.sendTo(mainWCid, specialIpcRoute.window2ToWindow1, 'lockPage');
				}
			});
        },
        minWindow() {
            // ipc.send("window-mini", this.windowId);
			ipc.invoke(ipcApiRoute.miniWindow, this.windowId).then(r => {})
        },
        closeWindow() {
            this.$confirm('提示',{
              title: '提示',
              message: '此操作将会关闭当前窗口，是否确认关闭？',
              confirmButtonText: '确定',
              cancelButtonText: '取消'
            }).then(res => {
              // ipc.send("window-closed", this.windowId)
			  ipc.invoke(ipcApiRoute.closeWindow, this.windowId).then(r => {})
            }).catch(err => {
              console.log(`用户点击取消${err}`)
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.top-nav{
    height: 60px;
    background: #fff;
    .nav-left {
        -webkit-app-region: no-drag;
        .nav-tilte{
            margin-left: 20px;
            font-size: 14px;
            font-family: 'font-Medium';
            cursor: pointer;
            div{
                font-size: 0;
            }
        }
        .position{
            div{
                background:#067CF2;
                width:6px;
                height:30px;
                border-radius:2px;
                margin-left: 10px;
                margin-right:20px;
            }
            p{
                font-family: 'font-Regular';
                font-size:20px;
            }
        }
    }
    .nav-center{
        flex: 1;
        .cashier-item{
            font-size: 14px;
            border: 1px solid #067cf2;
            background: rgb(230,247,255);
            border-radius: 4px;
            padding: 2px 4px;
            margin-left: 20px;
        }
    }
    .nav-right {
        -webkit-app-region: no-drag;
        height: 60px;
        .back-icon{
            img{
                width:36px;
                height:38px;
                cursor:pointer;
                float:left;
                margin-right:20px;
            }
        }
        .nav-right-btn {
            margin-right: 10px;
            background:#EFF7FF;
            height:36px;
            line-height:36px;
            padding:0 10px;
            border-radius:8px;
        }
        .size-icon {
            color: #333;
            font-size: 21px;
        }
    }
}
</style>